var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.detailFields, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                      on: { update: _vm.onUpdate },
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-2",
                      attrs: { header: "Righe", "header-tag": "header" },
                    },
                    [
                      _c("communication-detail", {
                        attrs: {
                          resourceId: _vm.resourceId,
                          extras: _vm.extras,
                        },
                        on: {
                          loadIndex: function ($event) {
                            return _vm.$emit("loadIndex")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }