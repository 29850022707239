var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center mt-5" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3374301958
              ),
            },
            [
              !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { align: "left" } },
                            [
                              _c(
                                "b-button-group",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle:collapse-1",
                                      arg: "collapse-1",
                                    },
                                  ],
                                  staticClass: "my-2 filter-button-group",
                                  attrs: {
                                    title: _vm.getDefaultFilterMessage(),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "when-open" },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "funnel",
                                          "font-scale": "1.5",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "when-closed" },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "funnel",
                                          "font-scale": "1.5",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v("\n            Filtra\n          "),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { align: "right" } },
                            [
                              _vm.canVerb(_vm.resource, "store")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-create",
                                      attrs: {
                                        type: "button",
                                        variant: "primary",
                                        size: "sm",
                                        title: "Crea",
                                      },
                                      on: { click: _vm.onAdd },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "plus-circle",
                                          "aria-hidden": "true",
                                        },
                                      }),
                                      _vm._v("\n            Crea"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { visible: "", id: "collapse-1" } },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSearch(_vm.filterName)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-card",
                                    { staticClass: "filter" },
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "status",
                                                label: "Stato",
                                                options: _vm.statusOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter.byAttribute
                                                    .status_procedure,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter.byAttribute,
                                                    "status_procedure",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.byAttribute.status_procedure",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "communication_type",
                                                label: "Tipo",
                                                options: _vm.typeOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter.byAttribute
                                                    .communication_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter.byAttribute,
                                                    "communication_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.byAttribute.communication_type",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-datepicker", {
                                              attrs: {
                                                vid: "da",
                                                name: "Creazione da",
                                                label: "Creazione da",
                                              },
                                              model: {
                                                value:
                                                  _vm.filter.byCalendar.from,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter.byCalendar,
                                                    "from",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.byCalendar.from",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-datepicker", {
                                              attrs: {
                                                vid: "a",
                                                name: "Creazione a",
                                                label: "Creazione a",
                                              },
                                              model: {
                                                value: _vm.filter.byCalendar.to,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter.byCalendar,
                                                    "to",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.byCalendar.to",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group col-md-3 align-self-end",
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-toggle",
                                                    rawName:
                                                      "v-b-toggle:collapse-1",
                                                    arg: "collapse-1",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "submit",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                              },
                                              [_vm._v("Cerca")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-reset",
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClearFilter(
                                                      _vm.filterName
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("communication-procedures", {
                        ref: _vm.tableRef,
                        attrs: {
                          fields: _vm.fields,
                          repository: _vm.repository,
                          resource: _vm.resource,
                          filterName: _vm.filterName,
                          onlyActions: ["edit"],
                          filterOn: {
                            byRelations: ["byDocument"],
                          },
                        },
                        on: {
                          edit: _vm.onEdit,
                          download: _vm.downloadDocument,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("select-option-modal", {
                            attrs: {
                              opts: _vm.typeOptions,
                              title: "Seleziona il tipo",
                            },
                            on: { select: _vm.onSelectOption },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }