var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-wizard",
        {
          ref: "wizard",
          attrs: {
            startIndex: _vm.startIndex,
            title: _vm.title,
            subtitle: _vm.subtitle,
            color: _vm.color,
            validateOnBack: true,
          },
          on: { "on-complete": _vm.onComplete, finish: _vm.onFinish },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "wizard-footer-left" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wizard-footer-right" },
                    [
                      !props.isLastStep
                        ? _c(
                            "wizard-button",
                            {
                              staticClass: "wizard-footer-right finish-button",
                              style: props.fillButtonStyle,
                              attrs: { disabled: _vm.isLoading },
                              nativeOn: {
                                click: function ($event) {
                                  return props.nextTab()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  props.isLastStep
                                    ? _vm.finished
                                      ? "Completato"
                                      : "Finisci"
                                    : "Avanti"
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "step",
              fn: function (props) {
                return [
                  _c("wizard-step", {
                    attrs: {
                      tab: props.tab,
                      transition: props.transition,
                      index: props.index,
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._l(_vm.tbs, function (tab) {
            return _c(
              "tab-content",
              {
                key: tab.title,
                attrs: {
                  title: tab.title,
                  icon: tab.icon,
                  lazy: tab.lazy,
                  "before-change": () =>
                    _vm.validateStep(tab.component, tab.index),
                },
              },
              [
                _c(
                  "b-overlay",
                  {
                    attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("base-icon", {
                                    attrs: {
                                      name: "loading",
                                      width: "35",
                                      height: "35",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("p", { attrs: { id: "cancel-label" } }, [
                                    _vm._v("Operazione in corso..."),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _c("h5", [_vm._v("Step #" + _vm._s(tab.index + 1))]),
                        _vm._v(" "),
                        !tab.hide
                          ? _c(tab.component, {
                              ref: tab.component,
                              refInFor: true,
                              tag: "component",
                              attrs: {
                                resourceId: _vm.resourceId,
                                extras: _vm.extras,
                              },
                              on: {
                                fetch: function ($event) {
                                  return _vm.$emit("fetch")
                                },
                                validate: _vm.validateStep,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("hr"),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }