var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _vm.isWizardReady
        ? _c(
            "div",
            [
              _c("wizard", {
                attrs: {
                  resourceId: parseInt(_vm.id),
                  repository: "communication_procedure",
                  title: _vm.wizardProps.title,
                  subtitle: _vm.wizardProps.subtitle,
                  color: _vm.wizardProps.color,
                  startIndex: _vm.wizardProps.startIndex,
                  extras: {
                    status_procedure: _vm.sts,
                    communication_type: _vm.communication_type,
                  },
                  tabs: _vm.wizardTabs,
                },
                on: {
                  fetch: function ($event) {
                    return _vm.loadIndex()
                  },
                  status: _vm.onStatus,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { align: "left" } },
            [
              _c(
                "b-button-group",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapse-1",
                      arg: "collapse-1",
                    },
                  ],
                  staticClass: "my-2 filter-button-group",
                  attrs: { title: "---" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v("\n        Filtra\n      "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { _visible: "", id: "collapse-1" } },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSearch(_vm.filterName)
                },
              },
            },
            [
              _c(
                "b-card",
                { staticClass: "filter" },
                [
                  _c(
                    "b-row",
                    [
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button-toolbar",
                              [
                                _c(
                                  "b-button-group",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        name: "Anagrafica",
                                        label: "Anagrafica",
                                        readonly: true,
                                      },
                                      model: {
                                        value: _vm.form.inpt_label_registry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "inpt_label_registry",
                                            $$v
                                          )
                                        },
                                        expression: "form.inpt_label_registry",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-4 btn-quick",
                                        attrs: {
                                          size: "sm",
                                          text: "Button",
                                          variant: "lisaweb",
                                          title: "Ricerca Veloce",
                                        },
                                        on: {
                                          click: _vm.openQuickSearchRegistry,
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "search" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-4 btn-quick ml-1",
                                        attrs: {
                                          size: "sm",
                                          text: "Button",
                                          variant: "lisaweb",
                                          title: "Elimina Anagrafica",
                                        },
                                        on: { click: _vm.resetRegistryId },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "trash" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _vm.registry_data
                          ? _c("span", {
                              staticClass: "info",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.toInfoData(_vm.registry_data, "registry")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("quick-search-registry-modal", {
                        ref: "quickSearchRegistry",
                        on: { input: _vm.handleRegistryModalInput },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("base-select", {
                            attrs: {
                              name: "scartate",
                              label: "Scartate",
                              options: _vm.discardedOptions,
                            },
                            model: {
                              value:
                                _vm.filter.byCommunicationDetail.is_discarded,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filter.byCommunicationDetail,
                                  "is_discarded",
                                  $$v
                                )
                              },
                              expression:
                                "filter.byCommunicationDetail.is_discarded",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-3 align-self-end" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle:collapse-1",
                                arg: "collapse-1",
                              },
                            ],
                            attrs: {
                              type: "submit",
                              variant: "lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("Cerca")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-reset",
                            attrs: {
                              type: "button",
                              variant: "lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClearFilter(_vm.filterName)
                              },
                            },
                          },
                          [_vm._v("Reset")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isWizardReady
        ? _c(
            "div",
            [
              _c("communication-groupings", {
                ref: _vm.tableRef,
                attrs: {
                  filterOn: {
                    byRelations: [
                      "byRegistry",
                      "byInsurancePayment",
                      "byBookEntry",
                      "byInsuranceAncillary",
                    ],
                  },
                  fields: _vm.fields,
                  repository: "communication_grouping",
                  resource: "communication_groupings",
                  filterName: _vm.filterName,
                  onlyActions: ["infomodal"],
                  extras: {
                    status_procedure: _vm.sts,
                    communication_type: _vm.communication_type,
                  },
                },
                on: { loadIndex: _vm.loadIndex },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }