var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "lg",
        "modal-tall": "",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "house" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Crea riga")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { attrs: { id: "cancel-label" } }, [
                            _vm._v("Operazione in corso..."),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _c("validation-observer", {
                        ref: "observer",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ invalid, handleSubmit }) {
                                return [
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { staticStyle: { height: "280px" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-3",
                                            },
                                            [
                                              _c("base-datepicker", {
                                                attrs: {
                                                  vid: "date",
                                                  name: "Data",
                                                  label: "Data",
                                                  rules: { required: true },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form[_vm.rep]
                                                      .custom_data.date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form[_vm.rep]
                                                        .custom_data,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form[rep].custom_data.date",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-3",
                                            },
                                            [
                                              _c("base-input", {
                                                attrs: {
                                                  vid: "description",
                                                  name: "Descrizione",
                                                  label: "Descrizione",
                                                  placeholder:
                                                    "Inserisci una descrizione",
                                                  rules: { required: true },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form[_vm.rep]
                                                      .custom_data.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form[_vm.rep]
                                                        .custom_data,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form[rep].custom_data.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-3",
                                            },
                                            [
                                              _c("base-currency", {
                                                attrs: {
                                                  vid: "gross",
                                                  name: "Valore",
                                                  label: "Valore",
                                                  options: {
                                                    currency: "EUR",
                                                    locale: "it-IT",
                                                    precision: 2,
                                                  },
                                                  rules: { required: true },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form[_vm.rep]
                                                      .custom_data.gross,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form[_vm.rep]
                                                        .custom_data,
                                                      "gross",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form[rep].custom_data.gross",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-2 float-right",
                                          attrs: {
                                            type: "button",
                                            disabled: invalid,
                                            variant: "outline-lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return handleSubmit(
                                                _vm.saveCommunicationDetail
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Salva\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2099552902
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }