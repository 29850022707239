var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
            },
            [
              _vm.extras.communication_type === 1
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v("SMS")]),
                      _vm._v(" "),
                      _c("sms", {
                        ref: _vm.tableRef.sms,
                        attrs: {
                          fields: _vm.fields.sms,
                          repository: "sms",
                          filterName: "filterSms",
                          resource: "sms",
                          hasChecks: "",
                          isCheckExclusive: "",
                          noActions: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.extras.communication_type === 0
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("email", {
                        ref: _vm.tableRef.email,
                        attrs: {
                          fields: _vm.fields.email,
                          repository: "email",
                          filterName: "filterEmail",
                          resource: "email",
                          hasChecks: "",
                          isCheckExclusive: "",
                          noActions: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v("PDF")]),
                      _vm._v(" "),
                      _c("pdf", {
                        ref: _vm.tableRef.pdf,
                        attrs: {
                          fields: _vm.fields.pdf,
                          repository: "pdf",
                          filterName: "filterPdf",
                          resource: "pdf",
                          hasChecks: "",
                          isCheckExclusive: "",
                          noActions: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.extras.communication_type === 2
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v("PDF")]),
                      _vm._v(" "),
                      _c("pdf", {
                        ref: _vm.tableRef.pdf,
                        attrs: {
                          fields: _vm.fields.pdf,
                          repository: "pdf",
                          filterName: "filterPdf",
                          resource: "pdf",
                          hasChecks: "",
                          isCheckExclusive: "",
                          noActions: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStepReady
                ? _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-3 align-self-end" },
                      [
                        _c("p", [_vm._v("Invia selezionati")]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "button",
                              variant: "lisaweb",
                              size: "sm",
                              disabled: !_vm.canSend,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSend()
                              },
                            },
                          },
                          [_vm._v("\n            Invia\n          ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }