var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "selectOption",
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [_c("span", [_vm._v(_vm._s(_vm.title))])]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("ANNULLA")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-outline-lisaweb",
                      attrs: {
                        disabled: _vm.option == null,
                        size: "sm",
                        variant: "outline-success",
                      },
                      on: {
                        click: function ($event) {
                          _vm.$emit("select", _vm.option)
                          cancel()
                        },
                      },
                    },
                    [_vm._v("CONTINUA")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("b-row", [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _vm._v(
            "\n      Per procedere è necessario selezionare un'opzione\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("base-select", {
              attrs: { name: "options", label: _vm.title, options: _vm.opts },
              on: { select: _vm.onSelectOption },
              model: {
                value: _vm.option,
                callback: function ($$v) {
                  _vm.option = $$v
                },
                expression: "option",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }