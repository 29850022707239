var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ invalid, handleSubmit }) {
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c("b-row", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group col-md-3 align-self-end",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                handleSubmit(_vm.onSubmit("N"))
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Aggrega\n              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                handleSubmit(_vm.onSubmit("Y"))
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Disaggrega\n              "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      878406106
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }